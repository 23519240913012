<template>
  <div id="html-builder-page">
    <HTMLEditor />
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>

import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import TipLabel from '@/view/components/TipLabel.vue';
import HTMLEditor from '@/view/components/html_editor/HTMLEditor.vue';

export default {
  name: 'HTMLBuilderPage',
  mixins: [ toasts ],
  components: {
    HTMLEditor,
    TipLabel,
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  mounted() {
    
  },
  data() {
    return {
      
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
      
      }
    },
    currentPeriodId(newValue, oldValue) {
      
    }
  },
  methods: {

  }
};
</script>
